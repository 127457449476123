.handOfCards {
  width: 1000px;
  height: 210px;
  display: flex;
  justify-content: center;
}

.handOfCards .cardWrapper {
  transform-origin: center 1000px;
  position: absolute;
}

.handOfCards .playingCard {
  cursor: pointer;
  transition: all ease-in-out 100ms;
  top: 0;
}

.handOfCards .cardWrapper:hover .playingCard {
  top: -0.3em;
  animation: card-wiggle 200ms ease-in-out infinite alternate;
  animation-delay: 100ms;
  transform-origin: center 100%;
}

.handOfCards .cardWrapper .playingCard.selected {
  top: -0.6em;
  animation: none;
}

@keyframes card-wiggle {
  from {
    transform: rotate(-1deg);
  }

  to {
    transform: rotate(1deg);
  }
}

.handOfCards .cardWrapper:nth-child(1) {
  transform: rotate(-20deg) translateX(-5em);
}

.handOfCards .cardWrapper:nth-child(2) {
  transform: rotate(-16.9deg) translateX(-4.2em);
}

.handOfCards .cardWrapper:nth-child(3) {
  transform: rotate(-13.8deg) translateX(-3.5em);
}

.handOfCards .cardWrapper:nth-child(4) {
  transform: rotate(-10.8deg) translateX(-2.7em);
}

.handOfCards .cardWrapper:nth-child(5) {
  transform: rotate(-7.7deg) translateX(-1.9em);
}

.handOfCards .cardWrapper:nth-child(6) {
  transform: rotate(-4.6deg) translateX(-1.2em);
}

.handOfCards .cardWrapper:nth-child(7) {
  transform: rotate(-1.5deg) translateX(-0.4em);
}

.handOfCards .cardWrapper:nth-child(8) {
  transform: rotate(1.5deg) translateX(0.4em);
}

.handOfCards .cardWrapper:nth-child(9) {
  transform: rotate(4.6deg) translateX(1.2em);
}

.handOfCards .cardWrapper:nth-child(10) {
  transform: rotate(7.7deg) translateX(1.9em);
}

.handOfCards .cardWrapper:nth-child(11) {
  transform: rotate(10.8deg) translateX(2.7em);
}

.handOfCards .cardWrapper:nth-child(12) {
  transform: rotate(13.8deg) translateX(3.5em);
}

.handOfCards .cardWrapper:nth-child(13) {
  transform: rotate(16.9deg) translateX(4.2em);
}

.handOfCards .cardWrapper:nth-child(14) {
  transform: rotate(20deg) translateX(5em);
}

@media (prefers-reduced-motion: reduce) {
  .handOfCards .playingCard {
    transition: none;
  }

  .handOfCards .cardWrapper:hover .playingCard {
    animation: none;
  }
}
