.connectionStatus {
  margin-bottom: 40px;
  font-size: 20px;
  line-height: 40px;
  text-align: center;
}

.connectionStatus.offline {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.connectionStatus.disconnected {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
