.pokerSeat {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 112px;
  padding-top: 112px;
}

.pokerSeatAvatar {
  position: absolute;
  top: 0;
  width: 96px;
  height: 96px;
  border: 8px solid black;
  border-radius: 50%;
}

.pokerSeatName {
  font-weight: 500;
  line-height: 30px;
  text-shadow: 1px 1px rgba(255,255,255,0.25);
  color: #111;
}

.pokerSeatCard {
  position: absolute;
  top: 0;
  right: -50%;
  transform: scale(0.75);
  animation: pokerSeatCardAppear 150ms ease-out forwards;
}

@keyframes pokerSeatCardAppear {
  from {
    transform: scale(0.3) rotateZ(25deg);
  }

  to {
    transform: scale(0.75) rotateZ(0);
  }
}

@media (prefers-reduced-motion: reduce) {
  .pokerSeatCard {
    animation: none;
  }
}
