@font-face {
  font-family: 'Mikado';
  src: url(./mikado-300-normal.eot);
  src: url(./mikado-300-normal.eot?#iefix) format('embedded-opentype'),
       url(./mikado-300-normal.woff2) format('woff2'),
       url(./mikado-300-normal.woff) format('woff'),
       url(./mikado-300-normal.ttf) format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Mikado';
  src: url(./mikado-300-italic.eot);
  src: url(./mikado-300-italic.eot?#iefix) format('embedded-opentype'),
       url(./mikado-300-italic.woff2) format('woff2'),
       url(./mikado-300-italic.woff) format('woff'),
       url(./mikado-300-italic.ttf) format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Mikado';
  src: url(./mikado-400-normal.eot);
  src: url(./mikado-400-normal.eot?#iefix) format('embedded-opentype'),
       url(./mikado-400-normal.woff2) format('woff2'),
       url(./mikado-400-normal.woff) format('woff'),
       url(./mikado-400-normal.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Mikado';
  src: url(./mikado-400-italic.eot);
  src: url(./mikado-400-italic.eot?#iefix) format('embedded-opentype'),
       url(./mikado-400-italic.woff2) format('woff2'),
       url(./mikado-400-italic.woff) format('woff'),
       url(./mikado-400-italic.ttf) format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Mikado';
  src: url(./mikado-500-normal.eot);
  src: url(./mikado-500-normal.eot?#iefix) format('embedded-opentype'),
       url(./mikado-500-normal.woff2) format('woff2'),
       url(./mikado-500-normal.woff) format('woff'),
       url(./mikado-500-normal.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Mikado';
  src: url(./mikado-500-italic.eot);
  src: url(./mikado-500-italic.eot?#iefix) format('embedded-opentype'),
       url(./mikado-500-italic.woff2) format('woff2'),
       url(./mikado-500-italic.woff) format('woff'),
       url(./mikado-500-italic.ttf) format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Mikado';
  src: url(./mikado-700-normal.eot);
  src: url(./mikado-700-normal.eot?#iefix) format('embedded-opentype'),
       url(./mikado-700-normal.woff2) format('woff2'),
       url(./mikado-700-normal.woff) format('woff'),
       url(./mikado-700-normal.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Mikado';
  src: url(./mikado-800-normal.eot);
  src: url(./mikado-800-normal.eot?#iefix) format('embedded-opentype'),
       url(./mikado-800-normal.woff2) format('woff2'),
       url(./mikado-800-normal.woff) format('woff'),
       url(./mikado-800-normal.ttf) format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Mikado';
  src: url(./mikado-800-italic.eot);
  src: url(./mikado-800-italic.eot?#iefix) format('embedded-opentype'),
       url(./mikado-800-italic.woff2) format('woff2'),
       url(./mikado-800-italic.woff) format('woff'),
       url(./mikado-800-italic.ttf) format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Mikado';
  src: url(./mikado-900-normal.eot);
  src: url(./mikado-900-normal.eot?#iefix) format('embedded-opentype'),
       url(./mikado-900-normal.woff2) format('woff2'),
       url(./mikado-900-normal.woff) format('woff'),
       url(./mikado-900-normal.ttf) format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Mikado';
  src: url(./mikado-900-italic.eot);
  src: url(./mikado-900-italic.eot?#iefix) format('embedded-opentype'),
       url(./mikado-900-italic.woff2) format('woff2'),
       url(./mikado-900-italic.woff) format('woff'),
       url(./mikado-900-italic.ttf) format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Harimau Dua';
  src: url(./Hanoded-HarimauDua.otf) format('opentype'),
       url(./Hanoded-HarimauDua.woff2) format('woff2');
  font-weight: normal;
  font-style: normal;
}
