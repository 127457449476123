.pageTitle {
  font-size: 48px;
  font-weight: 700;
  height: 75px;
  display: flex;
  justify-content: center;
}

@media (max-width: 575px) {
  .pageTitle {
    height: 55px;
    margin: 0;
    font-size: 36px;
  }
}
