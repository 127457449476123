body {
  margin: 0;
  font-family: Mikado, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 17px;
  line-height: 1.42857143;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-family: Mikado, sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 1.1;
}

h2 {
  font-family: Mikado, sans-serif;
  font-size: 21px;
  font-weight: 500;
  line-height: 1.1;
}

input[type="text"] {
  width: 100%;
  height: 50px;
  padding: 0 14px;
  font-size: 17px;
  line-height: 21px;
  border: 2px solid;
  border-color: #ccc;
  border-radius: 5px;
  outline: 0;
  box-sizing: border-box;
  position: relative;
  color: #000;
  background-color: #fff;
  box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.075);
  overflow: hidden;
}

input[type="text"]:focus {
  border-color: #00a9ff;
}

button {
  display: inline-block;
  box-sizing: border-box;
  height: 50px;
  width: auto;
  font-family: Mikado,sans-serif;
  font-size: 17px;
  font-weight: 400;
  text-shadow: 0 -1px 0 rgba(255,255,255,.2);
  margin: 0;
  padding: 14px 9px 12px 9px;
  border: 2px solid transparent;
  border-radius: 5px;
  outline: none;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  user-select: none;
  cursor: pointer;
  background-color: #eee;
  border-color: #C1C1C1;
  color: #777777;
}

button:disabled {
  opacity: .65;
  cursor: not-allowed;
}

button:hover, button:focus, button:active {
  background-color: #c1c1c1;
}

button:active {
  box-shadow: inset 0 3px 5px rgba(0,0,0,.125);
}

button.primary {
  background-color: #00a9ff;
  border-color: #0098E6;
  color: #fff;
}

button.primary:hover, button.primary:focus, button.primary:active {
  background-color: #0098E6;
}

.buttonSet {
  margin: 20px 0 10px;
}

.buttonSet button {
  margin-bottom: 10px;
}

.buttonSet button + button {
  margin-left: 10px;
}

.label {
  font-weight: bold;
  padding: 7px 0;
}

.label > input {
  margin-right: 5px;
}

.container {
  min-width: 340px;
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 100px 40px;
  box-sizing: border-box;
}

.spacingLeft {
  margin-left: 40px;
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: #333;
    color: #ddd;
  }

  input[type="text"] {
    color: #ddd;
    background-color: #222;
    border-color: #666;
  }

  button {
    background-color: #666;
    border-color: #777;
    color: #fff;
  }

  button:hover, button:focus, button:active {
    background-color: #777;
  }
}

@media (max-width: 575px) {
  .container {
    padding: 20px;
  }
}
