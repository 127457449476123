.playingCard {
  position: relative;
  width: 1.75em;
  height: 2.3em;
  font-size: 40px;
  font-weight: normal;
  font-family: 'Harimau Dua', sans-serif;
}

.playingCardFace {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border: 1px solid #666;
  border-radius: .13em;
  box-shadow: .03em .03em .08em #666;
  transition: transform 500ms;
  transform-style: preserve-3d;
}

.playingCardFace.frontFace {
  transform: rotateY(0deg);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  color: #333;
}

.playingCardFace.backFace {
  transform: rotateY(-180deg);
  background-color: #000;
  background-image: url(../assets/stamp-black.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 90%;
}

.playingCard.back .playingCardFace.frontFace {
  transform: rotateY(180deg);
}

.playingCard.back .playingCardFace.backFace {
  transform: rotateY(0deg);
}
