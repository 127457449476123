/* Based on https://codepen.io/pmk/pen/GgrJRq */

.pokerchip {
  margin: 1em;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 151px;
  height: 151px;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.5), 0 0 3px 0 rgba(0, 0, 0, 0.4) inset;
  border-radius: 50%;
  background-size: 151px 151px;
  background-position: center center;
}
.pokerchip:before {
  position: absolute;
  content: "";
  z-index: 1;
  width: 117px;
  height: 117px;
  border-radius: 50%;
  top: 9px;
  left: 9px;
  background-size: 151px 151px;
  background-position: center center;
}
.pokerchip .pokerchipText {
  z-index: 2;
  position: absolute;
  text-align: center;
  font: bold 48px/111px 'Arial', sans-serif;
  white-space: pre;
  width: 111px;
  height: 111px;
  border-radius: 50%;
  top: 20px;
  left: 20px;
  text-shadow: -1px -1px 0px rgba(0, 0, 0, 0.3), 1px 1px 0px rgba(255, 255, 255, 0.2);
}
/* Color */
.pokerchip {
  background-image: linear-gradient(0deg, transparent 0, transparent 67.5px, #fff 67.5px, #fff 83.5px, transparent 83.5px, transparent 151px), linear-gradient(60deg, transparent 0, transparent 97.4304px, #fff 97.4304px, #fff 113.4304px, transparent 113.4304px, transparent 151px), linear-gradient(120deg, #222222 0, #222222 97.4304px, #fff 97.4304px, #fff 113.4304px, #222222 113.4304px, #222222 151px);
}
.pokerchip:before {
  border: 8px solid #222222;
  background-image: linear-gradient(0deg, transparent 0, transparent 69.5px, #EBEBEB 69.5px, #EBEBEB 81.5px, transparent 81.5px, transparent 151px), linear-gradient(30deg, transparent 0, transparent 98.7104px, #EBEBEB 98.7104px, #EBEBEB 110.7104px, transparent 110.7104px, transparent 151px), linear-gradient(60deg, transparent 0, transparent 98.7104px, #EBEBEB 98.7104px, #EBEBEB 110.7104px, transparent 110.7104px, transparent 151px), linear-gradient(90deg, transparent 0, transparent 69.5px, #EBEBEB 69.5px, #EBEBEB 81.5px, transparent 81.5px, transparent 151px), linear-gradient(120deg, transparent 0, transparent 98.7104px, #EBEBEB 98.7104px, #EBEBEB 110.7104px, transparent 110.7104px, transparent 151px), linear-gradient(150deg, #000000 0, #000000 98.7104px, #EBEBEB 98.7104px, #EBEBEB 110.7104px, #000000 110.7104px, #000000 151px);
}
.pokerchip .pokerchipText {
  background: #222222;
  color: #EBEBEB;
}
