.avatarSelect {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 800px;
  max-height: 600px;
  border: 2px solid #ccc;
  border-radius: 5px;
  margin: 0 0 20px;
}

.avatarSelectFilter > input[type="text"] {
  width: calc(100% + 4px);
  box-sizing: border-box;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin: -2px -2px 0
}

.avatarSelectItems {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  overflow-y: auto;
}

/* filler space on last row */
.avatarSelectItems::after {
  content: "";
  flex: 100;
}

.avatarSelectItem {
  flex: 1;
  outline: none;
  display: flex;
}

.avatarSelectItemContent {
  margin: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid transparent;
  padding: 5px;
  cursor: pointer;
  outline: none;
  text-align: center;
}

.avatarSelectItemContent > input[type="radio"] {
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -7px;
  opacity: 0;
  pointer-events: none;
}

.avatarSelectItem.selected > .avatarSelectItemContent {
  border-color: rgb(0,169,255);
}

.avatarSelectItem:focus > .avatarSelectItemContent {
  box-shadow: 0 0 0 1px #000;
}

.avatarSelectNoMatchesMessage {
  width: 100%;
  height: 200px;
  margin: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (prefers-color-scheme: dark) {
  .avatarSelect {
    border-color: #666;
  }

  .avatarSelectItem:focus > .avatarSelectItemContent {
    box-shadow: 0 0 0 1px #fff;
  }
}
