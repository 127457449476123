.pokerSessionHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 800px;
  margin: 0 auto 40px;
}

.headlineWrap {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  align-self: stretch;
}

.headlineWrap .buttonSet {
  text-align: right;
}

.topicLastChangedBy {
  margin-left: 40px;
  font-size: 14px;
  font-weight: normal;
  color: #666;
}

.pokerSession .pokerTable {
  margin: 100px -100px 0;
}

.pokerSession .handOfCards {
  margin: -60px -100px 0;
}

.pokerSession .pokerchip {
  animation: pokerchipScaleIn ease-out 100ms forwards;
}

@keyframes pokerchipScaleIn {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

@media (prefers-reduced-motion: reduce) {
  .pokerSession .pokerchip {
    animation: none;
  }
}

@media (max-width: 1199px) {
  .pokerSession .pokerTable {
    width: auto;
    height: auto;
    margin: 0;
  }

  .pokerSession .pokerTable .pokerTableCenter {
    position: static;
    top: unset;
    left: unset;
    transform: none;
    transform: scale(0.5);
    display: flex;
    justify-content: center;
  }

  .pokerSession .pokerTable .pokerTableCenter .pokerchip {
    margin: -75px 0;
  }

  .pokerSession .pokerTableSeats {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin: 40px -15px 0;
  }

  .pokerSession .pokerTableSeats .pokerSeat {
    position: relative;
    top: unset;
    left: -15px;
    right: unset;
    transform: scale(0.75);
    margin: 5px 5px -30px;
  }

  .pokerSession .pokerTableSeats .pokerSeat .pokerSeatCard {
    top: 0;
    right: -50%;
    left: unset;
  }

  .pokerSession .pokerTableSeats .pokerSeat .pokerSeatName {
    position: static;
    text-align: center;
    left: unset;
    right: unset;
  }

  .pokerSession .pokerTableFrontSeat .pokerSeat {
    position: relative;
    top: unset;
    left: unset;
    transform: scale(0.75);
    margin: 0 auto -80px;
  }

  .pokerSession .handOfCards {
    width: auto;
    height: auto;
    margin: 50px -10px 0;
    flex-wrap: wrap;
  }

  .pokerSession .handOfCards .cardWrapper {
    position: relative;
    transform: none;
  }

  .pokerSession .handOfCards .cardWrapper .playingCard {
    margin: 4px;
  }
}

@media (max-width: 765px) {
  .pokerSession .handOfCards .cardWrapper .playingCard {
    font-size: 36px;
  }

  .topicLastChangedBy {
    display: block;
    margin: 10px 0 0;
  }
}

@media (max-width: 375px) {
  .pokerSession .handOfCards .cardWrapper .playingCard {
    font-size: 28px;
  }
}
