.leaveButton {
  position: absolute;
  top: 0;
  left: 0;
  padding: 20px;
  vertical-align: middle;
  line-height: 48px;
  cursor: pointer;
}

.leaveButton > .icon {
  float: left;
  font-size: 48px;
  font-weight: bold;
  margin-right: 10px;
}

.leaveButton .text {
  opacity: 0;
  transition: opacity 100ms;
}

.leaveButton:hover .text {
  opacity: 1;
}

.leaveButton:hover {
  animation: leaveButtonAnimation 500ms ease-in-out infinite alternate;
}

@keyframes leaveButtonAnimation {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-10px);
  }
}

@media (prefers-reduced-motion: reduce) {
  .leaveButton:hover {
    animation: none;
  }
}

@media (max-width: 575px) {
  .leaveButton {
    padding: 10px;
  }
}
