.pokerTable {
  position: relative;
  width: 1000px;
  height: 400px;
  box-sizing: border-box;
  background-color: #4aad4a;
  border: 15px solid #a95555;
  border-radius: 150px;
  box-shadow: inset 1px 1px 5px #000;
  margin-bottom: 40px;
}

.pokerTable:before {
  content: "";
  border-radius: 150px;
  box-shadow: inset 0 0 0 5px rgba(0,0,0,0.1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: -15px;
}

.pokerTableCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.pokerTableFrontSeat .pokerSeat {
  position: absolute;
  top: 450px;
  left: 430px;
}

.pokerTableFrontSeat .playingCard {
  display: none;
}

.pokerTableSeats .pokerSeat {
  position: absolute;
}

.pokerTableSeats .pokerSeat:nth-child(9) {
  top: 260px;
  left: -40px;
}

.pokerTableSeats .pokerSeat:nth-child(7) {
  top: 110px;
  left: -70px;
}

.pokerTableSeats .pokerSeat:nth-child(5) {
  top: -30px;
  left: -10px;
}

.pokerTableSeats .pokerSeat:nth-child(3) {
  top: -80px;
  left: 150px;
}

.pokerTableSeats .pokerSeat:nth-child(1) {
  top: -80px;
  left: 320px;
}

.pokerTableSeats .pokerSeat:nth-child(2) {
  top: -80px;
  right: 320px;
}

.pokerTableSeats .pokerSeat:nth-child(4) {
  top: -80px;
  right: 150px;
}

.pokerTableSeats .pokerSeat:nth-child(6) {
  top: -30px;
  right: -10px;
}

.pokerTableSeats .pokerSeat:nth-child(8) {
  top: 110px;
  right: -70px;
}

.pokerTableSeats .pokerSeat:nth-child(10) {
  top: 260px;
  right: -40px;
}

.pokerTableSeats .pokerSeat:nth-child(2) .pokerSeatCard,
.pokerTableSeats .pokerSeat:nth-child(4) .pokerSeatCard,
.pokerTableSeats .pokerSeat:nth-child(6) .pokerSeatCard,
.pokerTableSeats .pokerSeat:nth-child(8) .pokerSeatCard,
.pokerTableSeats .pokerSeat:nth-child(10) .pokerSeatCard {
  right: auto;
  left: -50%;
}

.pokerTableSeats .pokerSeat:nth-child(1) .pokerSeatCard,
.pokerTableSeats .pokerSeat:nth-child(2) .pokerSeatCard,
.pokerTableSeats .pokerSeat:nth-child(3) .pokerSeatCard,
.pokerTableSeats .pokerSeat:nth-child(4) .pokerSeatCard,
.pokerTableSeats .pokerSeat:nth-child(5) .pokerSeatCard,
.pokerTableSeats .pokerSeat:nth-child(6) .pokerSeatCard {
  top: 15px;
}

.pokerTableSeats .pokerSeat:nth-child(9) .pokerSeatCard,
.pokerTableSeats .pokerSeat:nth-child(10) .pokerSeatCard {
  top: -20px;
}

.pokerTableSeats .pokerSeat:nth-child(5) .pokerSeatName {
  left: 30px;
  text-align: left;
  position: relative;
}

.pokerTableSeats .pokerSeat:nth-child(6) .pokerSeatName {
  right: 30px;
  text-align: right;
  position: relative;
}

.pokerTableSeats .pokerSeat:nth-child(7) .pokerSeatName {
  left: 80px;
  text-align: left;
  position: relative;
}

.pokerTableSeats .pokerSeat:nth-child(8) .pokerSeatName {
  right: 80px;
  text-align: right;
  position: relative;
}
.pokerTableSeats .pokerSeat:nth-child(9) .pokerSeatName {
  text-align: right;
  position: relative;
}

.pokerTableSeats .pokerSeat:nth-child(10) .pokerSeatName {
  text-align: left;
  position: relative;
}

@media (prefers-color-scheme: dark) {
  .pokerTableFrontSeat .pokerSeatName {
    color: inherit;
  }
}
